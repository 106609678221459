# Regex courtesy of http://vuejs.org/examples/firebase.html
emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

Vue.config.delimiters = [
  '[['
  ']]'
]

new Vue(
  
  el: '#featured-file'
  
  data: 
    showFile: false
    error: false
    email: ''
    name: ''

  methods:
    handleSubmit: (event) ->
      @error = false
      if @name is ''
        @error = 'Please provide your name.'
        this.$els.name.focus()
      else if @email is '' or !emailRE.test @email
        @error = 'Please provide a valid email address.'
        this.$els.email.focus()
      else
        event.target.submit()
)