updateJS = ->
  $('html').removeClass('no-js').addClass('js')

toggleMobileNav = ->
  $('.nav-trigger').on 'click', ->
    console.log 'click'
    menu = $('nav.mobile').toggleClass 'active'
    if menu.hasClass 'active' then menu.slideDown(200) else menu.slideUp(200)

makeLinksExternal = ->
  $('.page-content p a').attr 'target', '_blank'
  $('.page-content.internal-links p a').attr 'target', ''

# handleDownloadTrigger = ->
#   $('.download-trigger').on 'click', (e) ->
#     e.preventDefault()
#     window.open $(e.target).attr('href'), '_blank'

#     window.setTimeout(( ->
#       window.location = '/services'  
#     ), 200)

handleDownloadGuide = ->
  linkTag = document.querySelector('.download-trigger')

  if linkTag
    linkTag.addEventListener 'click', (e) ->
      e.preventDefault()
      # get the element the event listener is attached to with `currentTarget`
      currentTarget = e.currentTarget
      urlLink = currentTarget.getAttribute('href')
      # open the link in a new tab
      window.open urlLink, '_blank'
      # # redirect the page
      window.setTimeout(( ->
        window.location = '/services'
      ), 250)

$(document).on 'ready', ->
  updateJS()
  # toggleMobileNav()
  makeLinksExternal()
  # handleDownloadTrigger()
  handleDownloadGuide()

