# Regex courtesy of http://vuejs.org/examples/firebase.html
emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

Vue.config.delimiters = [
  '[['
  ']]'
]

new Vue(
  
  el: '#contact'
  
  data: 
    invalidName: false
    invalidEmail: false
    name: ''
    email: ''
    submittingForm: false

  methods:

    resetForm: () ->
      @invalidName = false
      @invalidEmail = false

    validateName: () ->
      return @name isnt ''

    validateEmail: () ->
      if @email isnt '' and emailRE.test @email
        @validEmail = true
      else 
        @validEmail = false

    checkName: () ->
      @invalidName = !@validateName()

    checkEmail: () ->
      @invalidEmail = !@validateEmail()

    submitForm: (event) ->
      console.log 'clicked'
      @resetForm()
      @checkName()
      @checkEmail()
      if @invalidName
        this.$els.name.focus()
      else if @invalidEmail
        this.$els.email.focus()
      else 
        @submittingForm = true
        event.target.submit()
)